import "./App.css";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Particles from "react-particles-js";
import AboutMe from "./components/AboutMe";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
$(window).on("load", function () {
    $(".second-button").on("click", function () {
        $(".animated-icon2").toggleClass("open");
        $(".navbar-nav").toggleClass("open");
    });

    $(".navbar-nav .nav-link").on("click", function () {
        $(".animated-icon2").removeClass("open");
        $("navbar-nav").removeClass("open");
    });
});

function App() {
    return (
        <>
            <Particles
                className="particles-canvas"
                params={{
                    particles: {
                        number: {
                            value: 30,
                            density: {
                                enable: true,
                                value_area: 900,
                            },
                        },
                        shape: {
                            type: "circle",
                            stroke: {
                                width: 6,
                                color: "#f9ab00",
                            },
                        },
                    },
                }}
            />
            <Navbar />
            <Header />
            <AboutMe />
            <Skills />
            <Experience />
            <Portfolio />
            {/* <Contacts /> */}
            <Footer />
        </>
    );
}

export default App;
