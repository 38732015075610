import React, { useState, useEffect } from "react";
import $ from "jquery";
import logo from "../logo3-01.png";
import { Link } from "react-scroll";
//React Fontawesome Import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Squash as Hamburger } from "hamburger-react";
import Aos from "aos";
import "aos/dist/aos.css";

const Navbar = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        const hideMenu = document.getElementById("navbarSupportedContent");
        hideMenu.classList.remove("show");
        const handleToggler = document.getElementById("toggler");
        handleToggler.classList.add("collapsed");
    };

    const handleState = (e) => {
        e.preventDefault();
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    $(window).on("load", function () {
        $(".second-button").on("click", function () {
            $(".animated-icon").toggleClass("open");
        });
    });
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark fixed-top">
            <div className="container">
                <Link data-aos="fade-right" smooth={true} to="home" className="navbar-brand" href="#">
                    <img className="logo" src={logo} alt="logo"></img>
                </Link>

                <button
                    id="toggler"
                    onClick={handleState}
                    className="navbar-toggler second-button"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <div className="animated-icon2">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div data-aos="fade-left" className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <Link smooth={true} to="home" className="nav-link" href="#" onClick={handleClick}>
                                Home <span className="sr-only">(current)</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="about" offset={-125} className="nav-link" href="#" onClick={handleClick}>
                                About Me
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="skills" offset={-115} className="nav-link" href="#" onClick={handleClick}>
                                Skills
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="experience" offset={-110} className="nav-link" href="#" onClick={handleClick}>
                                Experience
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="portfolio" offset={-110} className="nav-link" href="#" onClick={handleClick}>
                                Portfolio
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link smooth={true} to="contacts" offset={-110} className="nav-link" href="#" onClick={handleClick}>
                                Contact
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
