import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Experience = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    return (
        <div id="experience" className="experience">
            <div className="d-flex justify-content-center my-5">
                <h1>experience</h1>
            </div>
            <div className="container experience-wrapper">
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>11/2022 - 10/2023</h3>
                        <h5>Technical Product Manager, Optum</h5>
                        <p>Innovation Team: Leveraged LLM's to streamline care delivery, improve patient outcomes, and reduce costs, enabling an enhanced clinical experience for both patients and providers.</p>
                        <p>Data Team: Maximized the value delivered by 2 Scrum teams via Product Backlog ownership, Agile ceremony participation, and stakeholder collaboration to facilitate the migration of clinical data from on-prem to cloud.</p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>05/2022 - 11/2022</h3>
                        <h5>Associate Product Manager, Optum</h5>
                        <p>Collaborated with our stakeholders to explore, validate, and pursue innovative solutions, such as a non-linear shop process, social shopping, and alternative payments, to create value for our members and business.</p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>01/2020 - 05/2022</h3>
                        <h5>Student, The Johns Hopkins University</h5>
                        <p>Master's of Science in Computer Science</p>
                        <p>Relevant Coursework: Algorithms, Big Data Processing, Cloud Computing, Data Structures, Front End Development, Principles of Enterprise Web Development</p>
                        <p>Activities and societies: JHU Product Management Club</p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>04/2017 - 01/2020</h3>
                        <h5>Owner/Operator, The UPS Store</h5>
                        <p>
                            Operated a store with 5 exceptional employees, cultivating a service culture while launching new print products, executing quarterly print and digital marketing campaigns, and establishing local small business consulting
                            to consistently place our location in the top 10% growth stores in the area.
                        </p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>04/2016 - 04/2017</h3>
                        <h5>Manager, The UPS Store</h5>
                        <p>
                            Responsible for the day-to-day operations, including opening/closing the center, managing productivity, ensuring my team of 7 delivered world-class customer service to all customers, monitoring cost control and expenses,
                            and providing weekly and monthly reports to the franchise owner.
                        </p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>01/2016 - 04/2016</h3>
                        <h5>Retail Associate, The UPS Store</h5>
                        <p>
                            Delivered world-class customer service to all retail customers by receiving and processing packages for courier shipment and operating copiers, fax machines, binding equipment, laminating machines, and point-of-sale
                            devices.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;
