import React, { useEffect } from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";

const Header = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    return (
        <div id="home" className="header-wraper">
            <div data-aos="zoom-in" className="main-info">
                <h3>
                    I am<span> </span>
                    <Typed
                        className="typed-text"
                        strings={[" a developer.", " a designer.", " an innovator.", " a leader."]}
                        typeSpeed={90}
                        backSpeed={60}
                        backDelay={2000}
                        smartBackspace={false}
                        loop
                    />
                </h3>
                {/* <Link smooth={true} to="contacts" offset={-110} href="#" className="btn-main-offer">
                    Contact Me
                </Link> */}
            </div>
        </div>
    );
};

export default Header;
