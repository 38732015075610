import React, { useEffect } from "react";
import author from "../me.jpeg";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutMe = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    return (
        <div id="about" className="about">
            <div className="container py-5">
                <h1 className="about-heading">About Me</h1>
                <div className="row">
                    <div className="col-lg-6 col-xm-12">
                        <div className="photo-wrap mb-4">
                            <img className="profile-img" src={author} alt="author" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <p>
                            I’ve always had the desire to create great experiences. From my days of performing as a percussionist to organizing international dance competitions, the goal was to deliver the best possible experience for the customer.
                            When I joined The UPS Store, that very goal is what led me to build an application that would help us decrease customer wait times and elevate the customer experience. Through developing this application, I became
                            fascinated with technology and began to see the immense value a software solution could bring to a business and its customers.
                            <br></br>
                            <br></br>
                            Previously serving as a Fullstack Engineer and Product Manager at Optum, I utilized my varied experiences in technology, business, psychology, and the performing arts to create innovative solutions which helped people live
                            healthier lives and helped make the health system work better for everyone.
                            <br></br>
                            <br></br>
                            Hobbies/Interests: Dance, entrepreneurship, golf, music production, podcasts, reading, web app development
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
