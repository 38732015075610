import { faLaptopCode, faPaintBrush, faUserTie } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from "aos";
import "aos/dist/aos.css";

const Skills = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    return (
        <div id="skills" className="skills">
            <h1 className="py-5">Skills</h1>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-xs-12">
                        <div className="box">
                            <div className="circle">
                                <FontAwesomeIcon className="icon" icon={faLaptopCode} size="2x" />
                            </div>
                            <h3>Technical</h3>
                            <div className="skills-box">
                                <ul className="skills-list">
                                    <li>Java- Spring Boot</li>
                                    <li>JavaScript- Node, React</li>
                                    <li>Python</li>
                                    <li>SQL</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xs-12">
                        <div className="box">
                            <div className="circle">
                                <FontAwesomeIcon className="icon" icon={faPaintBrush} size="2x" />
                            </div>
                            <h3>Design</h3>
                            <ul className="skills-list">
                                <li>Adobe Illustrator</li>
                                <li>Adobe Photoshop</li>
                                <li>Adobe XD</li>
                                <li>Figma</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xs-12">
                        <div className="box">
                            <div className="circle">
                                <FontAwesomeIcon className="icon" icon={faUserTie} size="2x" />
                            </div>
                            <h3>Soft</h3>
                            <ul className="skills-list">
                                <li>Creative Problem Solving</li>
                                <li>Leadership</li>
                                <li>Networking</li>
                                <li>Public Speaking</li>
                                <li>Time Management</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;
