import React from "react";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 align-items-center">
                            <div className="row">
                                <div className="col">
                                    <Link smooth={true} to="home" offset={-110} href="#" className="footer-nav">
                                        Home
                                    </Link>
                                    <br />
                                    <Link smooth={true} to="about" offset={-110} href="#" className="footer-nav">
                                        About Me
                                    </Link>
                                    <br />
                                    <Link smooth={true} to="skills" offset={-110} href="#" className="footer-nav">
                                        Skills
                                    </Link>
                                </div>
                                <div className="col">
                                    <Link smooth={true} to="experience" offset={-110} href="#" className="footer-nav">
                                        Experience
                                    </Link>
                                    <br />
                                    <Link smooth={true} to="portfolio" offset={-110} href="#" className="footer-nav">
                                        Portfolio
                                    </Link>
                                    <br />
                                    {/* <Link smooth={true} to="contacts" offset={-110} href="#" className="footer-nav">
                                        Contact Me
                                    </Link> */}
                                </div>
                                <div className="col">
                                    <a href="http://www.linkedin.com/in/varunkashyap13" target="_blank" className="circle">
                                        <FontAwesomeIcon className="icon" icon={faLinkedin} size="3x" />
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="http://www.github.com/varunkashyap13" target="_blank" className="circle">
                                        <FontAwesomeIcon className="icon" icon={faGithub} size="3x" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
