import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import clueless from "../images/LobbyPrototype.jpg";
import StudentRegistration from "../images/studentRegistration.jpg";
import spotify from "../images/spotify.jpg";
import garm from "../images/mainGarm-01.jpg";
import todo from "../images/todo-01.jpg";

//FONT AWESOME IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
//REACT POPUPBOX
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

const Portfolio = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, disable: "mobile" });
    }, []);

    //Clueless
    const openPopupboxClueless = () => {
        const content = (
            <>
                <img className="portfolio-image-popupbox" src={clueless} alt="Clueless" />
                <br></br>
                <b>GitHub:</b>{" "}
                <a className="hyper-link" href="#" onClick={() => window.open("http://www.github.com/varunkashyap13/clueless")}>
                    Clue-Less
                </a>
                <br></br>
                <b>Youtube:</b>{" "}
                <a className="hyper-link" href="#" onClick={() => window.open("https://www.youtube.com/watch?v=9te90cbqR8c")}>
                    Live Demo
                </a>
                <p>Technologies Used: JavaScript, Redis, MongoDB, HTML/CSS, Docker, Adobe XD</p>
                <p>
                    Description: Served as the developer and product manager for the team. Created product roadmap, conducted market research, assesed
                    risks, built out client-server architecture, developed gameplay UI, conducted unit and integration testing.
                </p>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Clue-Less Web Application Project",
                },
            },
        });
    };
    const popupboxConfigClueless = {
        titleBar: {
            enable: true,
            text: "Clue-Less",
        },
        fadeIn: true,
        fadeInSpeed: 500,
    };

    //Student Registration
    const openPopupboxStudentRegistration = () => {
        const content = (
            <>
                <img className="portfolio-image-popupbox" src={StudentRegistration} alt="Student Registration" />
                <br></br>
                <b>GitHub:</b>{" "}
                <a className="hyper-link" onClick={() => window.open("http://www.github.com/varunkashyap13/studentRegistration")}>
                    Student Registration Web App
                </a>
                <p>Technologies Used: H2 relational database, HTML/CSS, Java (EJB, JDBC, JSF, Servlet), Maven, Spring, SQL, WildFly </p>
                <p>Description: Developed an MVC-based course registration system, with support for both student and faculty access.</p>
                <p>Features: login authentication, session tracking, relational database hook-up for dynamic response generation.</p>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Student Registration System- Web Application Project",
                },
            },
        });
    };
    const popupboxConfigStudentRegistration = {
        titleBar: {
            enable: true,
            text: "Student Registration System",
        },
        fadeIn: true,
        fadeInSpeed: 500,
    };

    //Spotify
    const openPopupboxSpotify = () => {
        const content = (
            <>
                <img className="portfolio-image-popupbox" src={spotify} alt="Spotify Case Study" />
                <br></br>
                <b>Case Study:</b>{" "}
                <a
                    className="hyper-link"
                    onClick={() => window.open("https://drive.google.com/file/d/1aFJpelIPTz_st4BgrHGlIDquFdk0w0u1/view?usp=sharing")}
                >
                    View pdf{" "}
                </a>
                <br></br>
                <b>Youtube:</b>{" "}
                <a className="hyper-link" onClick={() => window.open("https://youtu.be/gxb7doiALyQ")}>
                    Prototype Live Demo{" "}
                </a>
                <p>Technologies Used: Adobe Illustrator, Adobe Photoshop, InVision </p>
                <p>Description: A PM-oriented case study analyzing the impact of an in-app messaging feature on Spotify user engagement.</p>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Spotify Case Study",
                },
            },
        });
    };
    const popupboxConfigSpotify = {
        titleBar: {
            enable: true,
            text: "Spotify Case Study",
        },
        fadeIn: true,
        fadeInSpeed: 500,
    };

    //Todo
    const openPopupboxTodo = () => {
        const content = (
            <>
                <img className="portfolio-image-popupbox" src={todo} alt="Todo List Web App" />
                <br></br>
                <b>GitHub:</b>{" "}
                <a className="hyper-link" onClick={() => window.open("https://github.com/varunkashyap13/todo_list")}>
                    Todo App
                </a>
                <br></br>
                <p>Technologies Used: Gems, Git, Heroku, Ruby on Rails</p>
                <p>
                    Description: Created an MVC-based to-do list application which handled authentication/authorization, CRUD operations, database
                    migrations, nested resources, ORM.
                </p>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Todo List Web App",
                },
            },
        });
    };
    const popupboxConfigTodo = {
        titleBar: {
            enable: true,
            text: "Todo List Web App",
        },
        fadeIn: true,
        fadeInSpeed: 500,
    };

    //Garm
    const openPopupboxGarm = () => {
        const content = (
            <>
                <img className="portfolio-image-popupbox" src={garm} alt="Garm, Ink Web Application" />
                <br></br>
                <b>GitHub:</b>{" "}
                <a className="hyper-link" onClick={() => window.open("https://github.com/varunkashyap13/GarmInk")}>
                    Garm, Ink
                </a>
                <br></br>
                <p>Technologies Used: CSS, HTML, Java (EJB, JavaMail API, JDBC, JSP, Servlet), Maven, SQL, SSL</p>
                <p>Description: Designed an E-commerce dynamic web application following MVC architecture for apparel brand Garm, Ink</p>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Garm, Ink E-commerce Web App",
                },
            },
        });
    };
    const popupboxConfigGarm = {
        titleBar: {
            enable: true,
            text: "Garm, Ink E-commerce Web App",
        },
        fadeIn: true,
        fadeInSpeed: 500,
    };

    return (
        <div id="portfolio" className="portfolio-wrapper">
            <div className="container">
                <h1 className="text-uppercase text-center py-5">portfolio</h1>
                <div className="image-box-wrapper row justify-content-center">
                    <div className="portfolio-image-box" onClick={openPopupboxClueless}>
                        <img className="portfolio-image" src={clueless} alt="Clueless"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxStudentRegistration}>
                        <img className="portfolio-image" src={StudentRegistration} alt="Student Registration"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxSpotify}>
                        <img className="portfolio-image" src={spotify} alt="Spotify Case Study"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxGarm}>
                        <img className="portfolio-image" src={garm} alt="Garm, Ink E-commerce Web App"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxTodo}>
                        <img className="portfolio-image" src={todo} alt="Todo List Web App"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                </div>
            </div>
            <PopupboxContainer {...popupboxConfigClueless} />
            <PopupboxContainer {...popupboxConfigStudentRegistration} />
            <PopupboxContainer {...popupboxConfigSpotify} />
            <PopupboxContainer {...popupboxConfigGarm} />
            <PopupboxContainer {...popupboxConfigTodo} />
        </div>
    );
};

export default Portfolio;
